<template>
  <div>
    <section class="section-navbar sticky-top">
      <Navbar />
    </section>
    <v-app id="inspire">
      <v-main>
        <div class="container-fluid fill-height">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-8 col-md-4">
              <v-img src="@/assets/img/logo_color.png" class="mb-4 mt-5" />
              <FrontendLogin />
            </div>
          </div>
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Navbar from '@/components/navbar/index.vue';
import FrontendLogin from '@/views/login/component/frontendLogin.vue';

export default {
  name: 'Login',

  components: {
    Navbar,
    FrontendLogin,
  },
};
</script>

<style lang="scss" scoped></style>
